<template>
  <div>
    <!-- pc -->
    <div class="pc container-top" v-if="visible.pc && detail">
      <div class="container" v-if="detail">
        <div class="h5 main" style="padding:60px 0 52px 0">주문제작 상담 상세</div>
        <div class="flex">
          <div class="col-4" style="padding-right: 30px">
            <div class="detail-title">서비스 정보</div>
            <div style="margin-bottom:80px">
              <div class="subtitle7 sub3">진행 상태</div>
              <div class="flex">
                <div :class="`status status-${detail.status}`">{{ contentStatus(detail) }}</div>
              </div>
              <div class="subtitle7 sub3 word-wrap">서비스 이름</div>
              <div class="body2 sub2">{{ detail.service_name }}</div>
              <div class="subtitle7 sub3">서비스 소개</div>
              <div class="body2 sub2 word-wrap">{{ detail.content }}</div>
              <div class="subtitle7 sub3">제작예산</div>
              <div class="body2 sub2">{{ detail.account|currencyNum }}만원</div>
              <div class="subtitle7 sub3">희망 소요기간</div>
              <div class="body2 sub2">{{ detail.hope_date }}개월</div>
              <template v-if="detail.file && detail.file !== ''">
                <div class="subtitle7 sub3">참고자료</div>
                <div class="body2 sub2 flex-align">
                  <img src="/static/icon/u_paperclip.svg"
                       class="svg-sub3 margin-right-8"
                       style="width:18px;height:18px"/>
                  <a :href="detail.file" target="_blank">{{ detail.file.split('/').pop() }}</a></div>
              </template>
              <div class="subtitle7 sub3">서비스 지원환경</div>
              <div class="body2 sub2">{{ detail.support }}</div>
            </div>

            <div class="detail-title">신청자 정보</div>
            <div>
              <div class="subtitle7 sub3">성함(혹은 회사명)</div>
              <div class="body2 sub2">{{ detail.name }}</div>
              <div class="subtitle7 sub3">연락처</div>
              <div class="body2 sub2">{{ detail.phone }}</div>
              <div class="subtitle7 sub3">이메일</div>
              <div class="body2 sub2">{{ detail.email }}</div>
            </div>
          </div>
          <div class="col-8" style="padding-left:30px">
            <launch-talk :talkId="detail.talk" :talks.sync="talks"></launch-talk>
          </div>
        </div>

        <div class="flex-center" style="margin-top:80px">
          <button class="button is-gray" style="width:242px" @click="routerPush('/mypage/inquiry')">목록으로</button>
        </div>
      </div>
    </div>
    <!-- mobile -->
    <div class="mobile" v-if="visible.mobile && detail">
      <!-- 헤더 -->
      <div class="header-mobile" v-if="!vOverlay">
        <div class="flex-between" style="height:60px">
          <div class="flex-align">
            <img class="header-mobile-back" src="/static/icon/u_arrow-left.svg" @click="$router.back()">
            <div class="header-mobile-title">{{ detail.service_name }}</div>
          </div>
          <button class="button is-primary-light" @click="openOverlay">내 주문 요청서</button>
        </div>
        <div class="flex-align" style="height:40px;padding:0 8px">
          <lp-check-bool :checkedVal.sync="req_email" @onCheck="onCheckEmail"
                         label="답변을 이메일 알림으로 받겠습니다."></lp-check-bool>
        </div>
      </div>

      <!-- 채팅 -->
      <launch-talk :talkId="detail.talk" :talks.sync="talks" ref="talk" @onCheckEmail="res => req_email = res"></launch-talk>

      <!-- 주문 요청서 오버레이 -->
      <div class="overlay-detail" v-show="vOverlay">
        <div class="header-overlay">
          <div style="width:32px"></div>
          <div class="subtitle5 main">내 주문 요청서</div>
          <img src="/static/icon/fi_close.svg" @click="closeOverlay">
        </div>
        <div class="detail-title">서비스 정보</div>
        <div class="flex">
          <div :class="`status status-${detail.status}`">{{ contentStatus(detail) }}</div>
        </div>
        <div class="subtitle7 sub3" style="border-top:0">서비스 이름</div>
        <div class="body4 sub2">{{ detail.service_name }}</div>
        <div class="subtitle7 sub3">서비스 소개</div>
        <div class="body4 sub2">{{ detail.content }}</div>
        <div class="subtitle7 sub3">제작예산</div>
        <div class="body4 sub2">{{ detail.account|currencyNum }}만원</div>
        <div class="subtitle7 sub3">희망 소요기간</div>
        <div class="body4 sub2">{{ detail.hope_date }}개월</div>
        <div class="subtitle7 sub3">참고자료</div>
        <div class="body4 sub2 flex-align" v-if="detail.file && detail.file!==''">
          <img src="/static/icon/u_paperclip.svg"
               class="svg-sub3"
               style="width:18px;height:18px;margin-right:6px"/>
          <a :href="detail.file" target="_blank">{{ detail.file.split('/').pop() }}</a></div>
        <div class="subtitle7 sub3">서비스 지원환경</div>
        <div class="body4 sub2">{{ detail.support }}</div>
        <div class="detail-title" style="margin-top:24px">신청자 정보</div>
        <div class="subtitle7 sub3" style="border-top:0;margin-top:0">성함(혹은 회사명)</div>
        <div class="body4 sub2">{{ detail.name }}</div>
        <div class="subtitle7 sub3">연락처</div>
        <div class="body4 sub2">{{ detail.phone }}</div>
        <div class="subtitle7 sub3">이메일</div>
        <div class="body4 sub2">{{ detail.email }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  import LaunchTalk from "../component/LaunchTalk";
  import LpCheckBool from "../module/LpCheckBool";

  export default {
    name: "ServiceInquiryDetail",
    components: {LpCheckBool, LaunchTalk},
    mixins: [
      UserAPIMixin
    ],
    created() {
      this.loginRequired();
      this.resizeEvent();
      this.getData();
      window.onpopstate = () => {
        this.popState();
      };
      window.addEventListener('resize',this.resizeEvent);
    },
    beforeDestroy() {
      window.removeEventListener('resize',this.resizeEvent);
    },
    data() {
      return {
        visible: {
          pc: false,
          mobile: false
        },
        detail: undefined,
        vOverlay: false,
        talks: [],
        req_email: false
      }
    },
    methods: {
      onCheckEmail() {
        this.$refs.talk.req_email = this.req_email;
        this.$refs.talk.onCheckEmail(this.req_email);
      },
      resizeEvent() {
        if(window.innerWidth < 1000) {
          this.visible.pc = false;
          this.visible.mobile = true;
        } else {
          this.visible.pc = true;
          this.visible.mobile = false;
        }
      },
      popState() {
        document.getElementsByTagName('html')[0].style.overflow = '';
        if(this.vOverlay) {
          this.vOverlay = false;
        }
      },
      openOverlay() {
        this.vOverlay = true;
        document.getElementsByTagName('html')[0].style.overflow = 'hidden';
        history.pushState(null, '', location.href);
      },
      closeOverlay() {
        this.vOverlay = false;
        history.back();
      },
      getData() {
        this.request.user.get(`launchpack/service/inquiry/${this.$route.query.id}`).then(res => {
          this.detail = res.data;
        });
      },
      contentStatus(menu) {
        let status = {
          0: '신청 미확인',
          1: '신청 확인',
          2: '상담완료'
        };
        let result = status[menu.status];
        if(menu.status === 2 && menu.service_status > 0) {
          result = menu.service_status === 1 ? '서비스 미등록' : '서비스 등록';
        }
        return result;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .detail-title
    color $main
    font-size 24px
    line-height 34px
    font-weight 500
    padding-bottom 16px
    border-bottom 1px solid $sub5
    margin-bottom 32px

  .grid
    grid-template-columns 0.3fr 1fr
    grid-row-gap 16px

  .clip
    transform rotate(45deg)
    color $sub3
    font-size 18px
    vertical-align sub
    margin-right 8px

  .subtitle7
    margin 24px 0 4px 0
  .subtitle7:first-child
    margin-top 0

  a
    text-decoration underline

  .word-wrap
    white-space pre-line
    word-break break-word

  @media (max-width:1024px)
    .detail-title
      font-size 16px
      font-weight 700
      padding-bottom 8px
      margin-bottom 16px
      color $main

    .subtitle7
      border-top 1px solid $gray2
      margin-top 12px
      padding-top 12px

    .header-mobile
      height 104px

    .header-overlay
      height 60px
      display flex
      align-items center
      justify-content space-between

    .header-mobile
    .header-overlay
      width 100%
      background-color white
      border-bottom 1px solid $gray1
      padding 0 16px 0 8px
      position fixed
      top 0
      left 0
      z-index 1
      .header-mobile-back
        width 36px
        height 36px
        padding 6px 4px 2px 4px
      .header-mobile-title
        font-size 16px
        font-weight 500
        color $main
        margin-left 16px
      .button
        width 96px
        height 32px
        line-height 29px
        border-radius 18px
        font-size 12px
        font-weight 500

    .overlay-detail
      position fixed
      top 0
      left 0
      z-index 2
      background-color white
      width 100%
      height 100vh
      overflow-y auto
      padding 84px 16px
</style>
